import axios from 'axios'
import { createBrowserHistory } from 'history'
import { getUrlParam } from '@/utils'
const history = createBrowserHistory()
// window.globalT = Math.floor(Math.random() * 13 * 1000000000000)
let service = axios.create({
  baseURL: 'https://interface.tiaotiaoyu.com',
  timeout: 3000
})


// 添加请求拦截器
service.interceptors.request.use((config) => {
  if (getUrlParam('utoken')) {
    localStorage.setItem('fishUtoken', getUrlParam('utoken'))
  }
  const utoken = localStorage.getItem('fishUtoken')
  config.params = { utoken, ...config.params }
  console.log(config.params)
  return config
}, (error) => {
  return Promise.reject(error)
})

// 添加响应拦截器
service.interceptors.response.use((response) => {
  if (response.data.code == -1) {
    localStorage.removeItem('fishUtoken')
    history.replace('/login')
    window.location.reload()
  }
  return response.data
}, (error) => {
  return Promise.reject(error)
})

export default service